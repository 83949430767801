import { FC, useState, useContext, useEffect } from 'react'
import { Card, Button, Row, Col, Form, Dropdown, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import SoftBadge from 'components/common/SoftBadge'
import AppContext from 'context/Context'
import CustomLinkLeft from 'components/common/CustomLinkLeft'
import { BsFillSendFill } from 'react-icons/bs'
import DependentComponent from '../components/showCardHoldersComponents/Dependent'
import {
	loadDataEditform,
	searchUpdateSubscription
} from 'services/vittamais-core/subscription/subscription'
import { IDataEditForm } from './interfaces'
import SUBSCRIPTION_STATUS from 'helpers/subscriptionStatus'
import HolderComponent from '../components/showCardHoldersComponents/Holder'
import { FaUserAlt } from 'react-icons/fa'
import { ImCancelCircle } from 'react-icons/im'
import { AiFillSetting } from 'react-icons/ai'
import { removeSubscription } from 'services/vittamais-core/subscription/subscription'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import InvoiceComponent from '../components/showCardInvoicesComponents/InvoiceComponent'
import { useLoading } from 'context/LoadingBar/LoadingBar'
import { FiRefreshCw } from 'react-icons/fi'
import { BsFillGiftFill } from 'react-icons/bs'
import ModalBenefits from '../components/modalBenefits/Index'
import { NumericFormat } from 'react-number-format'
import RECORRENCY_TYPES from 'helpers/recurrencyTypes'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import { MdOutlineAddCard } from 'react-icons/md'
import ModalAddPublicNumber from './components/ModalAddPublicNumber'
import CustomLoading from 'components/common/CustomLoading'
const FormCards: FC = function FormCards() {
	const { setLoading } = useLoading()
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [showLoading, setShowLoading] = useState(false)
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const navigate = useNavigate()
	const { id } = useParams()
	const [controlModalBenefits, setControlModalBenefits] = useState({
		show: false
	})
	const [controlModalAddPublicNumber, setControlModalAddPublicNumber] = useState(
		{
			show: false
		}
	)
	const [FormData, setFormData] = useState({
		id: '',
		public_number: '',
		contract_id: '',
		business_partner_id: '',
		holder_subscription_id: '',
		holder_subscription: '',
		type_dependents: '1',
		status: '',
		expire_at: '',
		created_at: '',
		business_partner: '',
		holderInfo: [],
		dependent_info: [],
		invoices_info: [],
		membership_tier_name: '',
		subscription_additional_products: [],
		recurrency: 1,
		payment_method: 1,
		total_value: 0,
		responsible_partner: '',
		holder_dependents_quantity: ''
	})
	const handleFieldChange = (e: any): void => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = (e: any) => {
		e.preventDefault()
		console.log('e,', FormData)
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}
	const loadDataForm = async () => {
		try {
			if (!id) return
			setLoading(true)
			let dataForm: IDataEditForm = await loadDataEditform(parseInt(id))
			let newForm = { ...FormData }
			newForm.id = dataForm.id
			newForm.business_partner = dataForm.business_partner.name
			newForm.contract_id = dataForm.contract_id
			newForm.public_number = dataForm?.public_number
				? dataForm?.public_number
				: ''
			newForm.dependent_info = dataForm.dependent_info
			newForm.holder_subscription_id = dataForm.holder_subscription_id
			newForm.business_partner_id = dataForm.business_partner_id
			newForm.created_at = dataForm.created_at
			newForm.status = dataForm.status
			newForm.holderInfo = dataForm.holderInfo
			newForm.invoices_info = dataForm.invoices_info
			newForm.recurrency = dataForm.recurrency
			newForm.payment_method = dataForm.payment_method
			newForm.total_value = dataForm.total_value / 100
			newForm.responsible_partner = dataForm.responsible_partner
			//só existe plano em assinaturas titulares
			newForm.membership_tier_name = dataForm?.membership_tiers?.name
			newForm.subscription_additional_products = dataForm.subscription_additional_products
			newForm.holder_dependents_quantity = dataForm.holder_dependents_quantity ? dataForm.holder_dependents_quantity : ''
			setFormData(newForm)
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_code == "subscription_not_found") {
				navigate('/errors/404');
			}
		}
		setLoading(false)
	}
	const renderDate = (dateString: any) => {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}

	const renderStatus = (status: string) => {
		if (status == SUBSCRIPTION_STATUS?.ACTIVATED?.toString()) {
			return (
				<SoftBadge pill bg='success'>
					Ativo
				</SoftBadge>
			)
		} else if (status == SUBSCRIPTION_STATUS?.CANCELED?.toString()) {
			return (
				<SoftBadge pill bg='danger'>
					Cancelado
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='warning'>
					Desativado
				</SoftBadge>
			)
		}
	}
	const disableDependent = async () => {
		try {
			if (!id) return
			let confirmed = await showConfirm({
				title: 'Atenção!',
				text: 'Tem certeza que deseja desativar este dependente?'
			})
			if (!confirmed) return
			await removeSubscription(parseInt(id))
			loadDataForm()
			showToast('Dependente desativado com sucesso!', 'success')
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'error',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}
			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Não conseguimos desativar este dependente, tente novamente.'
			})
		}
	}
	const searchUpdate = async () => {
		try {
			setShowLoading(true)

			await searchUpdateSubscription(parseInt(FormData?.id))
			await loadDataForm()
			setShowLoading(false)
			showToast('Assinatura atualizada com sucesso!', 'success')
		} catch (e: any) {
			setShowLoading(false)
			await loadDataForm()
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Erro na atualização da assinatura.'
			})
		}
	}
	useEffect(() => {
		loadDataForm()
	}, [id])
	return (
		<Row>
			<Col lg={12}>
				<Form onSubmit={handleSubmit}>
					<Card>
						<Card.Body>
							<div>
								<Row className='d-flex justify-content-end'>
									<Col sm='6'>
										<h5>
											Assinatura: #{id}{' '}
											{FormData?.holder_subscription_id ? (
												<strong><SoftBadge bg='primary' pill>Dependente</SoftBadge></strong>
											) : (
												<strong><SoftBadge bg='success' pill>Titular</SoftBadge></strong>
											)}
										</h5>
										<div>
											<h6 style={{ color: '#8d99a7' }}>
												{renderDate(FormData.created_at)}
											</h6>
										</div>
									</Col>
									<Col sm='6' align='end'>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}
										>
											<h5 style={{ color: '#757e89' }}>Status: </h5>{' '}
											<span style={{ position: 'relative', top: '-2px' }}>
												{renderStatus(FormData.status)}
											</span>
										</div>
									</Col>
								</Row>
								<Row className='d-flex justify-content-end mt-3'>
									<Col sm='6' className='mt-2'>
										{/* <CustomLinkLeft to='#' goback={true} /> */}
										<CustomLinkLeft to='/assinaturas' />
									</Col>
									<Col sm='6' align='end'>
										<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<Button
												className={'me-2'}
												type='button'
												variant='falcon-default'
												onClick={() => {
													window.open(`/contratos/${FormData.contract_id}`, '_blank')
												}}
												disabled={showLoading}
											>
												<BsFillSendFill
													style={{ position: 'relative', top: '-1px' }}
													size={18}
												/>{' '}
												Contrato
											</Button>{' '}
											<Button
												className={
													(FormData.holder_subscription_id &&
														FormData.status != SUBSCRIPTION_STATUS?.CANCELED?.toString()) ||
														!FormData?.holder_subscription_id
														? 'me-2'
														: ''
												}
												type='button'
												variant='falcon-default'
												onClick={() => {
													setControlModalBenefits({
														show: true
													})
												}}
											>
												<BsFillGiftFill
													style={{ position: 'relative', top: '-3px' }}
													size={18}
												/>{' '}
												Benefícios
											</Button>
											{!FormData?.holder_subscription_id ? (
												<Dropdown>
													<Dropdown.Toggle variant='falcon-default' id='dropdown-basic'>
														<AiFillSetting />
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Dropdown.Item onClick={searchUpdate} disabled={showLoading}>
															{' '}
															<FiRefreshCw
																style={{ position: 'relative', top: '-1px' }}
															/>{' '}
															Buscar Atualizações
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											) : (
												''
											)}
											{FormData.holder_subscription_id &&
												FormData.status != SUBSCRIPTION_STATUS?.CANCELED?.toString() ? (
												<Dropdown>
													<Dropdown.Toggle variant='falcon-default' id='dropdown-basic'>
														<AiFillSetting />
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Dropdown.Item
															href='#'
															onClick={() => {
																disableDependent()
															}}
														>
															{' '}
															<ImCancelCircle
																style={{ position: 'relative', top: '-1px' }}
															/>{' '}
															Cancelar Assinatura
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											) : (
												''
											)}
										</div>
									</Col>
								</Row>
							</div>
						</Card.Body>
					</Card>
					<Card className='mt-3'>
						<Card.Body>
							<Row>
								<Col lg='8'>
									<Form.Group>
										<Form.Label>
											Cliente{' '}
											<FaUserAlt
												style={{
													position: 'relative',
													top: '-2px',
													cursor: 'pointer',
													color: '#27bcfd'
												}}
												onClick={() => {
													window.open(`/clientes/${FormData.business_partner_id}`, '_blank')
												}}
											/>
										</Form.Label>
										<Form.Control
											value={FormData?.business_partner}
											name='name'
											readOnly={true}
											type='text'
											disabled={true}
										/>
									</Form.Group>
								</Col>
								<Col lg='4'>
									<Form.Group>
										<Form.Label>
											Número Cartão
											{!FormData?.public_number || FormData?.public_number == '' ? (
												<strong
													style={{
														fontWeight: 'bold',
														cursor: 'pointer',
														fontSize: '13px',
														marginLeft: '6px'
													}}
													onClick={() => {
														setControlModalAddPublicNumber({ show: true })
													}}
												>
													<span className='text-info'>
														{' '}
														Adicionar <MdOutlineAddCard />
													</span>
												</strong>
											) : null}
										</Form.Label>
										<Form.Control
											value={FormData?.public_number}
											name='name'
											onChange={handleFieldChange}
											type='text'
											disabled={true}
										/>
									</Form.Group>
								</Col>
							</Row>
							{!FormData?.holder_subscription_id ? (
    						(FormData?.membership_tier_name == "Diamante" 
							|| (FormData?.membership_tier_name && FormData?.holder_dependents_quantity)) ? (
								<>
									<Row className='mt-3'>
										<Col lg={4}>
											<Form.Group>
												<Form.Label>Valor do Contrato</Form.Label>
												<NumericFormat
													name='total_value'
													displayType={'input'}
													thousandSeparator={'.'}
													decimalSeparator={','}
													decimalScale={2}
													fixedDecimalScale={true}
													value={FormData.total_value}
													prefix={'R$ '}
													className='form-control'
													disabled={true}
													readOnly={true}
												/>
											</Form.Group>
										</Col>
										<Col lg={4}>
											<Form.Group>
												<Form.Label>Recorrência</Form.Label>
												<Form.Select
													name='recurrency'
													disabled={true}
													value={FormData.recurrency}
												>
													<option value=''>Selecionar</option>
													<option value={RECORRENCY_TYPES.MONTHLY}>Mensal</option>
													<option value={RECORRENCY_TYPES.YEARLY}>Anual</option>
													<option value={RECORRENCY_TYPES.SEMIANNUALLY}>Semestral</option>
												</Form.Select>
											</Form.Group>
										</Col>
										<Col lg={4}>
											<Form.Group>
												<Form.Label>Método de pagamento</Form.Label>
												<Form.Select
													name='payment_method'
													disabled={true}
													value={FormData.payment_method}
												>
													<option value=''>Selecionar</option>
													<option value={PAYMENT_METHOD.TICKET}>Boleto/Pix</option>
													<option value={PAYMENT_METHOD.CREDIT_CARD}>Cartão de Crédito</option>
													<option value={PAYMENT_METHOD.CCP}>Cartão de Crédito Parcelado</option>
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>
									<Row className='mt-3'>
										<Col lg='4'>
											<Form.Group>
												<Form.Label>Plano</Form.Label>
												<Form.Control
													name='membership_tier_name'
													type='text'
													value={FormData.membership_tier_name}
													className='form-control'
													disabled={true}
													readOnly={true}
												/>
											</Form.Group>
										</Col>
										<Col lg='4'>
											<Form.Group>
												<Form.Label>Dependentes</Form.Label>
												<Form.Control
													value={FormData?.holder_dependents_quantity}
													name='holder_dependents_quantity'
													readOnly={true}
													type='text'
													disabled={true}
												/>
											</Form.Group>
										</Col>
									</Row>
								</>
							) : (
								<Row className='mt-3'>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Valor do Contrato</Form.Label>
											<NumericFormat
												name='total_value'
												displayType={'input'}
												thousandSeparator={'.'}
												decimalSeparator={','}
												decimalScale={2}
												fixedDecimalScale={true}
												value={FormData.total_value}
												prefix={'R$ '}
												className='form-control'
												disabled={true}
												readOnly={true}
											/>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Recorrência</Form.Label>
											<Form.Select
												name='recurrency'
												disabled={true}
												value={FormData.recurrency}
											>
												<option value=''>Selecionar</option>
												<option value={RECORRENCY_TYPES.MONTHLY}>Mensal</option>
												<option value={RECORRENCY_TYPES.YEARLY}>Anual</option>
												<option value={RECORRENCY_TYPES.SEMIANNUALLY}>Semestral</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg={4}>
										<Form.Group>
											<Form.Label>Método de pagamento</Form.Label>
											<Form.Select
												name='payment_method'
												disabled={true}
												value={FormData.payment_method}
											>
												<option value=''>Selecionar</option>
												<option value={PAYMENT_METHOD.TICKET}>Boleto/Pix</option>
												<option value={PAYMENT_METHOD.CREDIT_CARD}>Cartão de Crédito</option>
												<option value={PAYMENT_METHOD.CCP}>Cartão de Crédito Parcelado</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
							))
							: ''}
							
						</Card.Body>

					</Card>
					<Col sm='12' className='mt-3'>
						{' '}
						<h5 style={{ fontWeight: 'bold' }}>Produtos Adicionais</h5>{' '}
					</Col>
					{FormData.subscription_additional_products?.length > 0 ? (
						<Card className='mt-3'>
							<Card.Body>
								<div>
									<Table responsive striped>
										<thead>
											<tr>
												<th scope='col'>ID</th>
												<th scope='col'>Produto</th>
												<th scope='col'>Preço</th>
											</tr>
										</thead>

										<tbody>
											{FormData.subscription_additional_products?.length > 0
												? FormData.subscription_additional_products?.map((item: any, index: number) => (
													<tr key={item.additional_product_id}>
														<td>{item.additional_product_id}</td>
														<td>{item.additional_product.name}</td>
														<td>
															{(item.price / 100).toLocaleString('pt-BR', {
																style: 'currency',
																currency: 'BRL'
															})}
														</td>
													</tr>
												))
												: null}
										</tbody>
									</Table>
								</div>
							</Card.Body>
						</Card>
					) : null}

					<div className='mt-4'>
						{FormData?.holder_subscription_id ? (
							<HolderComponent dataHolder={FormData?.holderInfo} isDark={isDark} />
						) : (
							<DependentComponent
								dataDependents={FormData?.dependent_info}
								formData={FormData}
								setFormData={setFormData}
								loadDataForm={loadDataForm}
								disabled={showLoading}
								isDark={isDark}
							/>
						)}
					</div>

					{!FormData?.holder_subscription_id ? (
						<InvoiceComponent
							subscriptionData={FormData}
							dataInvoices={FormData?.invoices_info}
							loadDataForm={loadDataForm}
							disabled={showLoading}
							isDark={isDark}
						/>
					) : null}
					<ModalBenefits
						show={controlModalBenefits.show}
						isDark={isDark}
						subscription_id={id}
						onClose={() => {
							setControlModalBenefits({ show: false })
						}}
					/>
					<ModalAddPublicNumber
						show={controlModalAddPublicNumber.show}
						onClose={response => {
							if (response?.action) {
								loadDataForm()
							}
							setControlModalAddPublicNumber({
								show: false
							})
						}}
						subscriptionId={FormData?.id}
					/>
				</Form>
			</Col>
		</Row>
	)
}

export default FormCards
